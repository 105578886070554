












import { Vue,Component, Prop, Emit } from "vue-property-decorator"

interface HeadTopType<T=string>{
    BackIcon:T;
    DownIcon:T;

    handleClickBack():void;
    handleClickDown():void;
}

@Component({ name:"HeadTop" })
export default class HeadTop extends Vue implements HeadTopType{
    BackIcon = require("$icon/Basics/GoBack.png")
    DownIcon = require("$icon/Basics/Down.png")

    handleClickBack(){
        this.$router.go(-1)
    }

    handleClickDown(){
        this.PullDown(true)
    }

    @Prop(String)
    title!:string

    get GetTitle(){
        return this.title
    }

    @Emit("PullDown")
    PullDown(data:any){
        return data
    }
}
