
























import { Vue,Component, Emit, Prop, Watch } from "vue-property-decorator"

interface SelWeekType<T=string>{
    List:any[]
    Index:number;
    handleSelChange(e:any):void;
    handleClose():void;
    handleConfirm():void;
    handleCoumputed():void;
}

@Component({ name:"SelWeek" })
export default class SelWeek extends Vue implements SelWeekType{
   List: any[] = [
       {
           text:'这是啥',
           value:0
       }
   ]
   Index = 0
   Show = false

   mounted() {
       if( JSON.stringify( this.GetData ) != "{}" && !this.GetBool ){
           this.handleCoumputed()   
       }
   }

   handleSelChange(e: any): void {
       let IndexArr = e.getIndexes()
       this.Index = IndexArr[0]
   }

   handleClose(){
       this.PullShow( !this.Show )
   }

   handleConfirm(){
       this.PullShow( !this.Show )
       this.PullData( this.List[ this.Index ] )
   }


   handleCoumputed(){
       let List = [ "一","二","三","四","五","六","七","八","九","十","十一","十二","十三","十四","十五","十六","十七","十八","十九","二十", ]
       let NewList:any[] = []
       List.forEach( (item,index)=>{
           NewList.push( {
               text:'第'+item+'周',
               value:index
           } )
       } )

       let Index = NewList.findIndex( a=>{
           let reg = new RegExp(a.text,'g')
           return reg.test( this.GetData.currentWeek )
       } )
       if ( Index < 0 )Index = 0;
       this.Index = Index
       NewList[ Index ] = NewList[ Index ][ "text" ] + ' (本周)'
       this.List = NewList

       this.PullData( {
           text:NewList[ Index ],
           value:Index
       } )
   }

   @Prop(Boolean)
   show!:boolean

   get GetShow(){
       return this.show
   }

   @Prop(Object)
   data!:any

   get GetData(){
       return this.data
   }

   @Prop(Boolean)
   bool!:boolean

   get GetBool(){
       return this.bool
   }

   @Watch("GetData")
   ChangeGetData(newVal:any){
       if( JSON.stringify( newVal ) != "{}" && !this.GetBool ){
           this.handleCoumputed()   
       }
   }

   @Watch("GetShow")
   ChangeGetShow(newVal:boolean){
       if( newVal != this.Show  ){
           this.Show = newVal
       }
   }

   @Emit("PullShow")
   PullShow(data:boolean){
       return data
   }

   @Emit("PullData")
   PullData(data:any){
       return data
   }
}
