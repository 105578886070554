















import { Vue,Component } from "vue-property-decorator"

interface ClassContLType<T=string>{
   List:Array<{ key:T;data:number[] }>
}

@Component({ name:"ClassContL" })
export default class ClassContL extends Vue implements ClassContLType{
   List = [
      {
         key:"上午",
         data:[1,2,3,4]
      },{
         key:"下午",
         data:[5,6,7,8]
      },{
         key:"晚上",
         data:[9,10,11,12]
      },
   ]
}
