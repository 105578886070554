























import { Vue,Component } from "vue-property-decorator"

interface HeadTimeType<T=string>{
    List:any[],
    Index:number;
    Year:number;
    Mouth:number;
    WeekList:string[]

    handleFilterTime(num:number):string;
    handleGetList():any|undefined;
    handleGetWeek(weekNum:number,atMouth:number,MaxMouth:number,day:number):any[];
    handleComputed( dayNum:number,atMouth:number,MaxMouth:number ):any|undefined;
}

@Component({ name:"HeadTime" })
export default class HeadTime extends Vue implements HeadTimeType{
   List: any[] = [ 0,1,2,3,4,5,6 ]
   Index = 0
   Year = 0;
   Mouth = 0;
   WeekList = ['一','二','三','四','五','六','日']

   mounted() {
       let time = setTimeout(()=>{
           this.handleGetList()   
           clearTimeout( time )
       })
   }

   handleFilterTime(num:number){
       if( num < 10 ){
           return "0" + num
       }else{
           return num + ""
       }
   }

   handleGetList() {
       let date = new Date()
       let year = date.getFullYear()
       let week = date.getDay() // 周
       this.Year = year
       this.Index = week - 1

       let day = date.getDate() // 日
       let atTime = date.getMonth() + 1 // 当月
       let afterTime = date.getMonth() + 2
       this.Mouth = atTime

       let beforeDate = Number( new Date( year,atTime,1 ) )
       let afterDate = Number( new Date( year,afterTime,1 ) )
       let ZOOMoney = ( afterDate - beforeDate ) / ( 1000 * 60 * 60 * 24 ) // 本月的最大天数
       this.List = this.handleGetWeek( week,atTime,ZOOMoney,day )
       console.log( this.List )
   }

   handleGetWeek( weekNum:number,atMouth:number,MaxMouth:number,day:number ):any[] {
       let List = []
       if ( weekNum === 1 ){
           for ( let i = 1; i <= 7; i ++ ){
               if ( i === 1 ){
                   List.push({
                       week:1,
                       mouth:atMouth,
                       day:day
                   })
               }else{
                   List.push({
                       week:i,
                       mouth:this.handleComputed( ( day + i ) - 1,atMouth,MaxMouth ).mouth ,
                       day:this.handleComputed( ( day + i ) - 1,atMouth,MaxMouth ).day
                   })
               }
           }
       }else{
           let n = 0,
               k = weekNum;
           for ( let i = 1; i <= 7; i ++ ){
               if ( i === weekNum ){
                   List.push({
                       week:i,
                       mouth:atMouth,
                       day:day
                   })
               }else{
                   if ( i < weekNum ){
                       k --;
                       List.push({
                           week:i,
                           mouth:this.handleComputed( day - k,atMouth,MaxMouth ).mouth,
                           day: this.handleComputed( day - k,atMouth,MaxMouth ).day
                       })
                   }else if ( i > weekNum ){
                       n ++;
                       List.push({
                           week:i,
                           mouth:this.handleComputed( day + n,atMouth,MaxMouth ).mouth,
                           day: this.handleComputed( day + n,atMouth,MaxMouth ).day
                       })
                   }
               }
           }
       }
       return List
   }

   handleComputed( dayNum:number,atMouth:number,MaxMouth:number ) {
       if ( dayNum > MaxMouth ){
           return {
               mouth:atMouth + 1,
               day:1
           }
       }else if ( dayNum < 1 ){
           return {
               mouth:atMouth - 1,
               day:MaxMouth
           }
       }else{
           return {
               mouth:atMouth ,
               day:dayNum
           }
       }
   }
}
