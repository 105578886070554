


































































import ScrollView from "@/components/ShareComponent/PullDownUp.vue"
import ClassContL from "@/components/ClassComponent/AllClass/ClassContL.vue"
import { Vue,Component, Prop, Watch } from "vue-property-decorator"

interface ListType<T=string>{
    good:Array<{ key:number;Show:boolean;name:T;content:T;[propsData:string]:any }>
    post:Array<{ key:number;Show:boolean;name:T;content:T;[propsData:string]:any }>
    night:Array<{ key:number;Show:boolean;name:T;content:T;[propsData:string]:any }>
}

interface HeadContType<T=string>{
    List:ListType[]

    handleFilterGe(state:number):string;
}

@Component({ name:"HeadCont",components:{ ScrollView,ClassContL } })
export default class HeadCont extends Vue implements HeadContType{
   List: ListType[] = [ ]

    handleFilterGe(state:number):string{
        switch(state){
            case 1:
                return 'HeadCont_R_I'
            case 2:
                return 'HeadCont_R_I ClassTwo'
            case 3:
                return 'HeadCont_R_I ClassThree'
            case 4:
                return 'HeadCont_R_I ClassFour'
            default :
                return 'HeadCont_R_I'
        }
    }

    @Prop(Array)
    list!:ListType[]

    get GetList(){
        return this.list
    }

    @Watch("GetList",{ deep:true })
    ChangeGetList(newVal:ListType[]){
        this.List = newVal
    }
}
