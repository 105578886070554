



























import { Notify,Dialog } from "vant"
import HeadTop from "@/components/ClassComponent/AllClass/HeadTop.vue"
import HeadClass from "@/components/ClassComponent/AllClass/HeadTime.vue"
import ClassCont from "@/components/ClassComponent/AllClass/ClassCont.vue"
import SelWeeek from "@/components/ClassComponent/AllClass/SelWeek.vue"
import { GetAllClass } from "@/Api/Class/index"
import Stroage from "@/util/Storage"
import InitData,{ DataCopy } from "@/util/ClassData"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface FullClassType<T=string>{
    userId:T|number;
    token:T;
    loadingShow:boolean
    Show:boolean
    Title:T;
    WeekData:any
    SelBool:boolean
    List:any[]
    SelDefaultIndex:number;
    UpDataIndex:number;

    handleClickHead(data:boolean):void;
    handleChangeShow(data:boolean):void;
    handleClickWeek(data:any):void;
    handleUpData(bool?:boolean):void;
    handleFilterError(res:any):any|void;
    handleFilterList(List:any[]):void;
    handleError(data:string):void;
}

@Component({ name:"FullClass",components:{ HeadTop,HeadClass,ClassCont,SelWeeek } })
export default class FullClass extends ZoomPage implements FullClassType{
   userId:string|number = ""
   token = ""
   loadingShow = false
   Show = false
   Title = "第十周"
   WeekData:any = {}
   SelBool = false
   List:any[] = []
   SelDefaultIndex = 0
   UpDataIndex = 0

   mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       this.handleUpData()
   }

   handleClickHead(data:boolean){
       this.Show = true//!this.Show
   }

   handleChangeShow(data:boolean){
       this.Show = data
   }

   handleClickWeek(data:any){
       if( !this.SelBool ){
           this.SelDefaultIndex = data.value
           this.SelBool = true
       }else{
           if( data.text != this.Title ){
               this.UpDataIndex = data.value - this.SelDefaultIndex
               this.handleUpData( true )
           }
       }
       this.Title = data.text
   }

   handleUpData(bool?:boolean){
       this.loadingShow = true
       GetAllClass({
           userId:this.userId,
           token:this.token
       },{
           weekPageNo:bool ? this.UpDataIndex : 0//-10
       }).then(res=>{
           this.loadingShow = false
           console.log( res )
           if( res.message.code === "200" ){
               this.WeekData = res.data
               this.handleFilterList(res.data.schoolTimetableCourseDtoList)
           }else if( res.message.code === "201" ){
               this.handleFilterError( res )
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

   handleFilterError(res:any){
       if ( res.message.code !== "201" )return;
       let List:number[] = [],
       PathList:string[] = [
           "/semester",
           "/class",
           "/major",
           "/collega",
           "/enter",
       ],
       Path = "";
       if ( /学年/g.test( res.message.msg ) ){
           List.push( 5 )
       }
       if ( /学院/g.test( res.message.msg ) ){
           List.push( 4 )
       }
       if ( /专业/g.test( res.message.msg ) ){
           List.push( 3 )
       }
       if ( /班级/g.test( res.message.msg ) ){
           List.push( 2 )
       }
       if ( /学期/g.test( res.message.msg ) ){
           List.push( 1 )
       }
       List = List.sort( (a,b)=>b-a )
       Path = PathList[ List[0] - 1 ]
       let Str = "请先绑定："
       List.forEach(item=>{
           switch ( item ) {
               case 5:
                   Str += " 学年"
                   break;
               case 4:
                   Str += " 学院"
                   break;
               case 3:
                   Str += " 专业"
                   break;
               case 2:
                   Str += " 班级"
                   break;
               case 1:
                   Str += " 学期"
                   break;
           }
       })
       Dialog.confirm({
           title: '温馨提醒',
           message: Str + "?",
           confirmButtonText:"确定绑定",
           cancelButtonText:"不用了",
           className:"HideClassName",
           overlayClass:"MaskHideClassName"
       }).then(res=>{
           this.$router.replace({
               path:Path,
               query:{
                   flow:"true"
               }
           })
       }).catch(cat=>cat)
   }

   handleFilterList(List:any[]){
       this.List = DataCopy( InitData )
       let reg1 = '周一',//new RegExp("一",'g'),
           reg2 = '周二',//new RegExp("二",'g'),
           reg3 = '周三',//new RegExp("三",'g'),
           reg4 = '周四',//new RegExp("四",'g'),
           reg5 = '周五',//new RegExp("五",'g'),
           reg6 = '周六',//new RegExp("六",'g'),
           reg7 = '周日';//new RegExp("日",'g');
       let WeekKeyList = [ reg1,reg2,reg3,reg4,reg5,reg6,reg7 ];
       let WeekList:any[] = []
       WeekKeyList.forEach( ( parent,parentIndex )=>{
           List.forEach( (item,index)=>{
               if ( parent == item.week ){
                   if ( Array.isArray( WeekList[ parentIndex ] ) ){
                       WeekList[ parentIndex ].push( item )
                   }else{
                       WeekList[ parentIndex ] = [ item ]
                   }
               }
           } )
           if ( !Array.isArray( WeekList[ parentIndex ] ) ){
               WeekList.push( [] )
           }
       } )
       let RepeatList:any = []
       WeekList.forEach( (parend:any[],parendIndex)=>{
           let data:any[] = [];
           parend.forEach( (item,index)=>{
               let Index = data.findIndex( (a:any)=>{
                   if( a.sortStart === item.sortStart && a.sortEnd === item.sortEnd ){
                       return true
                   }else{
                       return false
                   }
               } )
               if( Index < 0 ){
                   data.push( item )
               }
           } )
           data = data.sort((a,b)=>a.sortStart - b.sortStart)
           RepeatList.push( data )
       } )
       let DataList:any[] = this.List
       RepeatList.forEach( (parent:any[],parentIndex:number)=>{
           if( parent.length ){
               parent.forEach( (item,index)=>{
                   if( item.sortStart >= 1 && item.sortEnd <= 4 ){
                       let Num:number = ( Number( item.sortEnd ) + 1 ) - Number( item.sortStart )
                       DataList[ parentIndex ][ "good" ].splice( Number( item.sortStart ) - 1,Num,{
                           key:Num,
                           name:item.courseName,
                           content:item.classroom,
                           ...item
                       }  )
                   }else if( item.sortStart >= 5 && item.sortEnd <= 8 ){
                       let Num:number = ( Number( item.sortEnd ) + 1 ) - Number( item.sortStart )
                       DataList[ parentIndex ][ "post" ].splice( Number( item.sortStart ) - 5,Num,{
                           key:Num,
                           name:item.courseName,
                           content:item.classroom,
                           ...item
                       }  )
                   }else if( item.sortStart >= 9 && item.sortEnd <= 12 ){
                       let Num:number = ( Number( item.sortEnd ) + 1 ) - Number( item.sortStart )
                       DataList[ parentIndex ][ "night" ].splice( Number( item.sortStart ) - 8,Num,{
                           key:Num,
                           name:item.courseName,
                           content:item.classroom,
                           ...item
                       }  )
                   }
               } )
           }
       } )
       this.List = DataList
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
